<template>
    <el-dialog title="充值" :visible.sync="dialogVisible" :width="step == 1 ? '900px' : '450px'" @closed="closed"
        :close-on-click-modal="false" :modal-append-to-body="false">
        <div class="aliform" v-html="aliform" v-show="false"></div>
        <div>
            <div class="content_box" v-show="step == 1">
                <div class="tittle">选择优惠</div>
                <div class="item_box">
                    <div class="item" @click="itemclick(item)" :class="{ active: form.packageId == item.packageId }"
                        v-for="item in list" :key="item.packageId">
                        <div class="item_top">
                            <span style="font-size: 16px;margin-right:6px;">¥</span>
                            <span>{{ item.price }}</span>
                        </div>
                        <div class="item_bottom">原价：{{ item.coins }}</div>
                        <div class="zhe">{{ (item.price / item.coins) | priceFormat(1) }}折</div>
                    </div>
                </div>
                <div class="tittle">自定义充值</div>
                <el-form size="mini" :model="form" ref="form">
                    <el-row :gutter="30">
                        <el-col :span="24">
                            <el-form-item prop="item">
                                <div class="asd">
                                    <span style="margin-right: 10px;">充值金额</span>
                                    <el-input v-model="form.coins" :disabled="form.packageId > 0" placeholder="充值金额"
                                        type="number" style="width: 140px;"></el-input>
                                    <span style="font-size: 14px;color: #999;">（企业余额只可充值不可提现，只有推广收益可提现。）</span>
                                </div>

                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
           
            <div v-show="step === 2">
                <div class="step2">
                    <div class="item_top">
                        <span style="font-size: 16px;margin-right:6px;">¥</span>
                        <span>{{ form.coins }}</span>
                    </div>
                </div>
            </div>

        </div>
        <span slot="footer" class="dialog-footer" v-show="step == 1">
            <el-button @click="close" size="mini">取 消</el-button>
            <el-button type="primary" @click="submit" size="mini">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {

    data() {

        return {
            dialogVisible: false,
            list: [],
            form: {
                packageId: null,
                coins: 0
            },
            step: 1,
            aliform: ''
        };
    },
    mounted() { },
    methods: {
        async show() {
            await this.postRechargePackage();
            this.dialogVisible = true;
        },
        async submit() {
            const { status, data } = await this.postRecharge();
            this.aliform = data;
            console.log(data)
            this.$nextTick(() => {
                document.forms[0].submit();  //重点--这个才是跳转页面的核心,获取第一个表单并提交
            });

            if (status == 2) {
                this.step = 2;
            }
            // this.$refs.form.validate(valid => {
            //     if (valid) {
            //         this.onsubmit()
            //     } else {
            //         return false
            //     }
            // })
        },
        close() {
            this.dialogVisible = false
        },
        clear() {
            this.form = {
                packageId: null,
                coins: 0
            }
        },
        closed() {
            this.clear()
        },
        // 获取充值套餐列表
        async postRechargePackage() {
            const { data } = await this.$api.postRechargePackage();
            this.list = data;
            return new Promise((suc, err) => {
                suc(data)
            })
        },
        //账号充值
        async postRecharge() {
            const returnUrl = `${window.location.host}${window.location.pathname}`;
            const res = await this.$api.postRecharge({ ...this.form, returnUrl });
            return new Promise((suc, err) => {
                suc(res)
            })
        },
        async ondelete() {
            const { status, info } = await this.$api.deleteArchiveDir({ dirId: this.dirId });
            if (status === 200) {
                this.$message({
                    type: 'success',
                    message: info
                })
                this.$emit('success')
                this.dialogVisible = false;
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        },
        itemclick({ packageId }) {
            if (this.form.packageId == packageId) {
                this.clear()
            } else {
                this.form.packageId = packageId;
                this.form.coins = 0;
            }

        }
    },
};
</script>
<style lang='scss' scoped>
/deep/.el-dialog__body {
    padding: 0 20px;
}

.content_box {
    .tittle {
        font-family: "Source Han Sans CN";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;
    }

    .item_box {
        display: flex;
        flex-wrap: wrap;


        .item:nth-child(3n - 1) {
            margin: 0 22px;
        }


        .item {
            width: calc((100% - (22px *2)) /3);
            display: flex;
            align-items: center;
            height: 135px;
            border-radius: 4px;
            border: 1.5px solid #EEE;
            background: linear-gradient(90deg, #FFF 0%, #F8F8F8 100%);
            flex-direction: column;
            justify-content: center;
            margin-bottom: 22px;
            position: relative;

            &_top {
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: #262626;
            }

            &_bottom {
                color: #999 !important;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration: line-through;
            }

            .zhe {
                width: 32px;
                height: 20px;
                background: linear-gradient(111deg, #F07B41 2.95%, #EB412A 100%);
                position: absolute;
                right: 0;
                top: 0;
                color: #fff;
                font-family: "Source Han Sans CN";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                border-radius: 0 4px 0 4px;
            }
        }

        .item:nth-child(-3) {
            margin-bottom: 0;
        }

        .active {
            border: 2px solid #1F5AEC;

            .item_top {
                background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .zhe {
                right: -1px;
                top: -1px;
            }

        }
    }

    .asd {
        display: flex;
    }

}
</style>